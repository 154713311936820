.home {
  position: relative;

  &-left {
    padding: 5.7vw 8vw;
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
      gap: 30px;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: "Red Hat Display";

    &-lg {
      font-size: 50px;
      font-weight: 900;
      line-height: 130%;

      @media (min-width: 1024px) {
        width: 42vw;
        max-width: 580px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    &-creative {
      font-family: "Leckerli One", cursive;
      font-weight: 400;
      font-style: italic;
      letter-spacing: -1px;
      color: #1471c7;
    }

    &-sm {
      font-size: 18px;
      line-height: 150%;

      @media (min-width: 1024px) {
        width: 29vw;
        max-width: 410px;
      }
    }
  }

  &-learn-more {
    &-text {
      text-decoration: none;
    }
  }

  &-banner {
    position: relative;
    z-index: 1;

    @media (min-width: 1024px) {
      position: absolute;
      top: 100px;
      right: 3.6vw;
    }

    @media (min-width: 1440px) {
      left: 800px;
    }

    img {
      width: 51.4vw;
      max-width: 610px;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }

  &-bg-icon {
    position: absolute;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &-1 {
      top: 30px;
      left: 10px;
      width: 80px;
      height: 80px;
    }

    &-2 {
      top: 20px;
      left: 600px;
      width: 100px;
      height: 100px;

      @media (max-width: 1024px) {
        left: auto;
        right: 30px;
      }
    }

    &-3 {
      top: 460px;
      left: 50px;
      width: 60px;
      height: 60px;

      @media (max-width: 1024px) {
        top: 380px;
      }
    }

    &-4 {
      top: 400px;
      left: 400px;
      width: 100px;
      height: 100px;

      @media (max-width: 1024px) {
        left: auto;
        right: 60px;
        top: 300px;
      }
    }
  }
}
