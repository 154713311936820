.footer {
  padding-top: 30px;
  padding-bottom: 8px;
  padding-left: 6.7vw;
  padding-right: 6.7vw;

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    background-color: white;

    @media (max-width: 1024px) {
      flex-direction: column; // display links vertically
      align-items: center;
    }
  }

  &-gdg-container {
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media (max-width: 1280px) {
      width: 270px;
    }

    @media (max-width: 1024px) {
      align-items: center;
      width: 550px; // links are displayed vertically, more room for text
    }

    @media (max-width: 768px) {
      align-items: center;
      width: 290px;
    }
  }

  &-logo {
    width: 250px;
  }

  &-description {
    font-family: "Red Hat Display";
    font-size: 14px;

    @media (max-width: 1024px) {
      padding-left: 15px; // align by countering the effect of text wrapping
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 52vw;
    justify-content: space-between;
    padding-top: 20px;

    @media (max-width: 1280px) {
      width: 58vw; // text wrapped, more room for links
    }

    @media (max-width: 1024px) {
      flex-direction: column; // display links vertically
      padding-top: 0px; // no double vertical padding
    }
  }

  &-links {
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;

    @media (max-width: 1280px) {
      width: 36vw; // text wrapped, more room for links
    }

    @media (max-width: 1024px) {
      width: 200px;
      display: grid; // 2 * 2 grid for displaying sublinks in future
      grid-template-columns: auto auto;
      grid-template-rows: 40px 40px;
    }
  }

  &-link-header {
    color: black;
    font-family: "Red Hat Display";
    font-weight: bold;

    :visited {
      color: black;
    }
  }

  &-gamecraft {
    color: white;
    font-family: "Red Hat Display";
    font-weight: bold;
    text-decoration: none;

    &-container {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &-divider {
    border-color: transparent;
    background-color: #e5e5e5;
    height: 0.1px;
  }

  &-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-gdg {
      color: #9a9a9a;
      font: "Red Hat Display";
    }
  }
}
