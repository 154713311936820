.event-card {
  border-radius: 10px;
  background: #f8f8fa;
  padding: 2rem 1rem 1rem 2rem;
  flex-grow: 1;

  @media (max-width: 1024px) {
    padding: 3vw 1vw 1vw 3vw;
  }

  &-header {
    display: flex;
    flex-direction: column;

    &-tagline {
      color: var(--Blue, #1471c7);
      font-family: "Red Hat Display";
      font-size: 15.6px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 640px) {
        font-size: 12px;
      }
    }

    &-name {
      color: var(--Black, #1c1d1e);
      font-family: "Red Hat Display";
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;

      @media (max-width: 640px) {
        font-size: 20px;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: row;

    &-details {
      display: flex;
      flex-direction: column;
      width: auto;
      gap: 1rem;

      @media (max-width: 640px) {
        gap: 2vw;
      }
    }

    &-description {
      color: #595959;
      font-family: "Red Hat Display";
      font-size: 15.6px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @media (max-width: 640px) {
        font-size: 12px;
      }

      @media (max-width: 1024px) and (min-width: 1024px) {
      }
    }

    &-button {
      border-width: 0;
      padding: 8px 32px;
      border-radius: 50px;
      background: var(--Blue, #1471c7);
      color: var(--White-on-Black, #f8f8fe);
      font-family: "Red Hat Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: fit-content;
      text-decoration: none;

      @media (max-width: 640px) {
        font-size: 11.7px;
        padding: 7.4px 29.3px;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40%;
      max-width: 40%;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 0;

      img {
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
