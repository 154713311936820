.collaboration {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  &-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    max-width: 1000px;

    &-title {
      font-size: 22px;
      font-weight: 700;
    }

    &-description {
      text-align: center;
      line-height: 130%;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &-item {
    border: 1px solid rgba(0, 0, 0, 0.125);
    max-width: 1000px;

    &-header {
      padding: 15px;
      background-color: rgba(0, 0, 0, 0.03);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      &-img {
        max-height: 30px;
        max-width: 30px;
      }
    }

    &-body {
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      &-title {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
      }

      &-description {
        text-align: center;
        line-height: 130%;
      }
    }
  }
}
