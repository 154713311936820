@font-face {
  font-family: "Red Hat Display";
  src: url(./../../assets/fonts/RedHatDisplay-VariableFont_wght.ttf) format("truetype-variations");
  src: url(./../../assets/fonts/RedHatDisplay-VariableFont_wght.ttf) format("truetype") tech ("variations");
  font-weight: 100 1000;
  font-stretch: 25% 150%;
}

@font-face {
  font-family: "Leckerli One";
  src: url(./LeckerliOne-Regular.ttf);
}
