.professional-talks {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  &-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &-title {
      font-size: 22px;
      font-weight: 700;
    }

    &-description {
      text-align: center;
      max-width: 1000px;
    }
  }
}
