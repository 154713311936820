.contact {
  background: #1c1d1e;
}

.discord {
  display: flex;
  flex-direction: column;
  align-items: center;

  $excited-font-size-large: 21.3px;
  $excited-font-size-small: 16px;
  $join-font-size-large: 37.9px;
  $join-font-size-small: 28px;
  $button-text-font-size-large: 16px;
  $button-text-font-size-small: 12px;

  &-logo {
    padding-top: 50px;
    padding-bottom: 11px;
  }

  &-excited {
    color: #57f287;
    font-family: "Red Hat Display";
    font-weight: bold;
    font-size: $excited-font-size-large;
  }

  &-join {
    color: white;
    font-family: "Red Hat Display";
    font-weight: bold;
    font-size: $join-font-size-large;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  &-button-div {
    padding-bottom: 50px;
  }

  &-button-text {
    color: white;
    text-decoration: none;
    font-family: "Red Hat Display";
    font-weight: bold;
    font-size: $button-text-font-size-large;
  }

  @media (max-width: 768px) {
    &-excited {
      font-size: $excited-font-size-small;
    }
    &-join {
      font-size: $join-font-size-small;
    }
    &-button-text {
      font-size: $button-text-font-size-small;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.others {
  padding-bottom: 30px;
  $description-font-size-large: 20px;
  $description-font-size-small: 14px;

  &-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-family: "Red Hat Display";
    font-weight: bold;
    font-size: $description-font-size-large;
    padding-bottom: 15px;
  }

  &-button-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-button {
    filter: invert(96%) sepia(100%) saturate(0%) hue-rotate(352deg) brightness(115%) contrast(102%);

    &:hover {
      filter: invert(88%) sepia(72%) saturate(525%) hue-rotate(61deg) brightness(101%) contrast(90%);
    }
  }

  &-button-logo {
    height: 50px;
    width: 50px;
    padding: 5px; // easier to click
  }

  &-button-padding {
    padding-right: 40px;
  }

  @media (max-width: 768px) {
    &-description {
      font-size: $description-font-size-small;
    }
  }
}
