.workshops {
  padding: 25px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;

    @media (max-width: 1024px) {
      width: 100%;
    }

    &-image {
      width: 100%;
      max-width: 600px;
      align-self: center;
      img {
        width: 100%;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 130%;
      }
    }
  }

  &-list {
    width: 60%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;

    @media (max-width: 1024px) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: #9fd1ff;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1471c7;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #125798;
    }

    &-item {
      display: flex;
      flex-direction: row;
      text-decoration: none;

      &:visited {
        color: black;
      }

      &-image {
        height: 150px;
        width: 210px;
        flex-shrink: 0;
        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: 768px) {
          height: 90px;
          width: 150px;
        }

        @media (max-width: 480px) {
          height: 60px;
          width: 100px;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
      }

      &-decor {
        color: #1471c7;
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-weight: 700;
      }

      &-title {
        font-size: 22px;
        font-weight: 700;
        color: black;
      }

      &-description {
        font-size: 12px;
        line-height: 130%;
        color: black;
      }
    }
  }
}
